import React from 'react';
import { filterOperators } from './filterOperators';

const FilterOperatorsList = ({ item }) => {
  return (
    filterOperators.map((operator) => {
      if (item.param.type === 'date' && operator.value === 'notNull')
        return (
          <option value={operator.value} key={operator.value}>
            {operator.label}
          </option>
        );
      if (item.param.type === 'date' && operator.value === 'coincidence')
        return '';
      if (item.param.type !== 'date' && operator.value === 'range')
        return '';
      if (operator.value !== 'notNull')
        return (
          <option value={operator.value} key={operator.value}>
            {operator.label}
          </option>
        );
    })
  );
};

export default FilterOperatorsList;