import React from 'react';
import TableCell from '@mui/material/TableCell';
import { lineStyleInTable } from '../../../../utils/consts/styles';
import moment from 'moment';
import { Link } from 'react-router-dom';

const TeacherHeaderCells = ({row}) => {

  return <>
    <TableCell align="left">
      <Link style={lineStyleInTable} target="_blank"
            to={`/${row.education_type === "Контракт" ? 'contract' : 'quota'}/${row.id}`}
      >
        {row.date_creation === null ? 'Не определена' : moment(row.date_creation).format("DD.MM.YYYY")}
      </Link>
    </TableCell>
    <TableCell align="left">
      <Link style={lineStyleInTable} target="_blank"
            to={`/${row.education_type === "Контракт" ? 'contract' : 'quota'}/${row.id}`}
      >
        {row.education_type}
      </Link>
    </TableCell>
    <TableCell align="left">
      <Link style={lineStyleInTable} target="_blank"
            to={`/${row.education_type === "Контракт" ? 'contract' : 'quota'}/${row.id}`}
      >
        {row.hours_number}
      </Link>
    </TableCell>
    <TableCell align="left">
      <Link style={lineStyleInTable} target="_blank"
            to={`/${row.education_type === "Контракт" ? 'contract' : 'quota'}/${row.id}`}
      >
        {row.latin_name}
      </Link>
    </TableCell>
    <TableCell align="left">
      <Link style={lineStyleInTable} target="_blank"
            to={`/${row.education_type === "Контракт" ? 'contract' : 'quota'}/${row.id}`}
      >
        {row.russian_name}
      </Link>
    </TableCell>
    <TableCell align="left">
      <Link style={lineStyleInTable} target="_blank"
            to={`/${row.education_type === "Контракт" ? 'contract' : 'quota'}/${row.id}`}
      >
        {row.country}
      </Link>
    </TableCell>
    <TableCell align="left">
      <Link style={lineStyleInTable} target="_blank"
            to={`/${row.education_type === "Контракт" ? 'contract' : 'quota'}/${row.id}`}
      >
        {row.gender}
      </Link>
    </TableCell>
    <TableCell align="left">
      <Link style={lineStyleInTable} target="_blank"
            to={`/${row.education_type === "Контракт" ? 'contract' : 'quota'}/${row.id}`}
      >
        {row.contract_number}
      </Link>
    </TableCell>
    <TableCell align="left">
      <Link style={lineStyleInTable} target="_blank"
            to={`/${row.education_type === "Контракт" ? 'contract' : 'quota'}/${row.id}`}
      >
        {row.payment_status}
      </Link>
    </TableCell>
    <TableCell align="center">
      <Link className="margin_table" style={lineStyleInTable}
            target="_blank"
            to={`/${row.education_type === "Контракт" ? 'contract' : 'quota'}/${row.id}`}
      >
        {row.enrollment_order}
      </Link>
    </TableCell>
    <TableCell align="left">
      <Link style={lineStyleInTable} target="_blank"
            to={`/${row.education_type === "Контракт" ? 'contract' : 'quota'}/${row.id}`}
      >
        {row.enrollment}
      </Link>
    </TableCell>
  </>
}

export default TeacherHeaderCells;
