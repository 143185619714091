
export const filterOperators = [
  { value: 'coincidence', label: 'Содержит' },
  { value: 'equals', label: 'Равно' },
  { value: 'more', label: 'Больше' },
  { value: 'less', label: 'Меньше' },
  { value: 'moreE', label: 'Больше или равно' },
  { value: 'lessE', label: 'Меньше или равно' },
  { value: 'range', label: 'Диапазон' },
  { value: 'notNull', label: 'Поле не пустое'}
];