export const NameSpace = {
    User: 'USER',
    App: 'APP',
    FileManager: 'MANAGER',
}

export const fileSizes = {
    0: 'byte',
    1: 'KB',
    2: 'MB',
    3: 'GB'
}