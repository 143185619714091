import { getToken } from '../../../../utils/token';
import jwt_decode from 'jwt-decode';

export const getHeaderCells = () => {
  const token = getToken();
  const userRole = token === null ? 'Unauthorized' : jwt_decode(token).role;
  const teacherAccess = userRole === 'Преподаватель';

  return teacherAccess ? [
      {
        id: 'registered_in_system',
        disablePadding: true,
        label: 'Дата регистрации на ПОдИУ',
      },
      {
        id: 'russian_name',
        label: 'ФИО (кир.)',
      },
      {
        id: 'latin_name',
        label: 'ФИО (лат.)',
      },
      {
        id: 'hours_number',
        label: 'Кол-во часов',
      },
      {
        id: 'gender',
        label: 'Пол',
      },
      {
        id: 'education_type',
        label: 'Тип обучения',
      },
      {
        id: 'country',
        label: 'Страна',
      },
      {
        id: 'form_study',
        label: 'Форма обучения',
      }
    ]
    :
    [
      {
        id: 'date_creation',
        numeric: false,
        disablePadding: true,
        label: 'Дата создания',
      },
      {
        id: 'education_type',
        numeric: false,
        disablePadding: true,
        label: 'Тип обучения',
      },
      {
        id: 'hours_number',
        numeric: false,
        disablePadding: true,
        label: 'Кол-во часов',
      },
      {
        id: 'latin_name',
        numeric: false,
        disablePadding: false,
        label: 'ФИО (лат.)',
      },
      {
        id: 'russian_name',
        numeric: false,
        disablePadding: true,
        label: 'ФИО (кир.)',
      },
      {
        id: 'country',
        numeric: false,
        disablePadding: false,
        label: 'Страна',
      },
      {
        id: 'gender',
        numeric: false,
        disablePadding: false,
        label: 'Пол',
      },
      {
        id: 'contract_number',
        numeric: true,
        disablePadding: true,
        label: '№ договора',
      },
      {
        id: 'payment_status',
        numeric: true,
        disablePadding: true,
        label: 'Статус оплаты',
      },
      {
        id: 'enrollment_order',
        numeric: false,
        disablePadding: true,
        label: '№ о зачислении',
      },
      {
        id: 'enrollment',
        numeric: false,
        disablePadding: false,
        label: 'Зачисление',
      }
    ];
};