import React from 'react';
import TableCell from '@mui/material/TableCell';
import Checkbox from '@mui/material/Checkbox';
import TableRow from '@mui/material/TableRow';
import { getToken } from '../../../../utils/token';
import jwt_decode from 'jwt-decode';
import TeacherHeaderRows from './TeacherHeaderCells';
import AnyRolesHeaderRows from './AnyRolesHeaderCells';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ExtraStudentInfo from './ExtraStudentInfo/ExtraStudentInfo';

const TableRowComponent = ({ row, index, selected, setSelected, isItemSelected }) => {
  const token = getToken();
  const userRole = token === null ? 'Unauthorized' : jwt_decode(token).role;
  const isTeacherAccess = userRole === 'Преподаватель';

  const [open, setOpen] = React.useState(false);

  const handleClick = (student) => {
    const selectedIndex = selected.findIndex(el => el.id === student.id);

    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, student);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const labelId = `enhanced-table-checkbox-${index}`;

  return (
    <>
      <TableRow
        hover={true}
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={row.id}
        selected={isItemSelected}
        onClick={() => setOpen(prevState => !prevState)}
        sx={{ cursor: 'pointer' }}
      >
        {
          !isTeacherAccess
            ?
            <TableCell padding="checkbox">
              <Checkbox
                color="primary" checked={isItemSelected}
                inputProps={{ 'aria-labelledby': labelId, }}
                onClick={() => {
                  handleClick(row);
                }}
              />
            </TableCell>
            :
            <TableCell>
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  setOpen(!open);
                }}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </TableCell>
        }
        {isTeacherAccess ? <TeacherHeaderRows row={row} /> : <AnyRolesHeaderRows row={row} />}
      </TableRow>
      {isTeacherAccess && <ExtraStudentInfo row={row} open={open} />}
    </>
  );
};

export default TableRowComponent;