export const FilterEnumAnswers = {
  gender: {
    label: 'Пол',
    type: 'string',
    answers: [
      {
        id: 1,
        value: 'Мужской'
      },
      {
        id: 2,
        value: 'Женский'
      }
    ]
  },
  refund_enrollment_status: {
    label: 'Был ли зачислен возврат',
    type: 'string',
    answers: [
      {
        id: 1,
        value: 'Да'
      },
      {
        id: 2,
        value: 'Нет'
      },
      {
        id: 3,
        value: ''
      }
    ]
  },
  refund_status: {
    label: 'Статус возврата',
    type: 'string',
    answers: [
      {
        id: 1,
        value: 'В обработке'
      },
      {
        id: 2,
        value: 'Средства возвращены'
      },
      {
        id: 3,
        value: 'Нет'
      }
    ]
  },
  transfer_status: {
    label: 'Статус переноса',
    type: 'string',
    answers: [
      {
        id: 1,
        value: 'В обработке'
      },
      {
        id: 2,
        value: 'Средства перенесены'
      },
      {
        id: 3,
        value: 'Нет'
      }
    ]
  },
  RF_location: {
    label: 'Нахождение в РФ',
    type: 'string',
    answers: [
      {
        id: 1,
        value: 'Да'
      },
      {
        id: 2,
        value: 'Нет'
      }
    ]
  },
  started_learning: {
    label: 'Приступил к обучению',
    type: 'string',
    answers: [
      {
        id: 1,
        value: 'Да'
      },
      {
        id: 2,
        value: 'Нет'
      }
    ]
  },
  hours_number: {
    name: 'hours_number',
    label: 'Количество часов',
    type: 'string',
    answers: [
      {
        id: 1,
        value: '1008 (1.5 года 24-25 г.)'
      },
      {
        id: 2,
        value: '1008 (1 год 24-25 г.)'
      },
      {
        id: 3,
        value: '1008 (1.5 года 23-24 г.)'
      },
      {
        id: 4,
        value: '1008 (1 год 23-24 г.)'
      },
      {
        id: 5,
        value: '868 (23-24 уч.г.)'
      },
      {
        id: 6,
        value: '728 (23-24 уч.г.)'
      },
      {
        id: 7,
        value: '588 (23-24 уч.г.)'
      },
      {
        id: 8,
        value: '504 (23-24 уч.г.)'
      },
      {
        id: 9,
        value: '352 (23-24 уч.г.)'
      },
      {
        id: 10,
        value: '288 (23-24 уч.г.)'
      },
      {
        id: 11,
        value: '144 (23-24 уч.г.)'
      },
      {
        id: 12,
        value: '108 (23-24 уч.г.)'
      },
      {
        id: 13,
        value: '72 (23-24 уч.г.)'
      }
    ]
  },
  form_study: {
    label: 'Форма обучения',
    type: 'string',
    answers: [
      {
        id: 1,
        value: 'Очная'
      },
      {
        id: 2,
        value: 'Гибрид'
      },
      {
        id: 3,
        value: 'Онлайн'
      }
    ]
  },
  education_type: {
    label: 'Тип обучения',
    type: 'string',
    answers: [
      {
        id: 1,
        value: 'Контракт'
      },
      {
        id: 2,
        value: 'Квота'
      }
    ]
  },
  enrollment: {
    label: 'Статус зачисления',
    type: 'string',
    answers: [
      {
        id: 1,
        value: 'Зачислен'
      },
      {
        id: 2,
        value: 'Не зачислен'
      },
      {
        id: 3,
        value: 'Отчислен'
      }
    ]
  },
  status_1c: {
    label: 'Статус 1C',
    type: 'string',
    answers: [
      {
        id: 1,
        value: 'Прикреплен'
      },
      {
        id: 2,
        value: 'Не прикреплен'
      }
    ]
  },
  payment_status: {
    label: 'Статус оплаты',
    type: 'string',
    answers: [
      {
        id: 1,
        value: 'Оплачено'
      },
      {
        id: 2,
        value: 'Не оплачено'
      },
      {
        id: 3,
        value: 'Оплачено частично'
      }
    ]
  },
  scholarship: {
    label: 'Стипендия',
    type: 'string',
    answers: [
      {
        id: 1,
        value: 'Да'
      },
      {
        id: 2,
        value: 'Нет'
      }
    ]
  }
};