import React, { useCallback, useEffect, useState } from 'react';
import '../../Contract/Contract.css';
import { dateTextFieldStyle, listItemStyle, textFieldStyle } from '../../../../utils/consts/styles';
import { CircularProgress, MenuItem } from '@mui/material';
import TextField from '@mui/material/TextField';
import { getStudentsByIdArray } from '../../../../actions/student';
import iziToast from 'izitoast';
import CustomSingleDatePicker from '../../../datePicker/CustomSingleDatePicker';

const FundsTransferStudentCard = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [transferStatus, setTransferStatus] = useState('Нет');

  useEffect(() => {
    props.changeData('transfer_status', transferStatus);
  }, [transferStatus]);

  useEffect(() => {
    props.changeData('transfer_date', props.transferDate);
  }, [props.transferDate]);

  useEffect(() => {
    if (props.transferDate || props.formData['transfer_date'])
      setTransferStatus('Средства перенесены');
    else if (
      props.formData['transfer_amount']
      || props.formData['refund_date_receipt_application']
      || props.formData['refund_date_transfer_accounting']
      || props.formData['refund_name']
      || props.formData['refund_comment']
      || props.formData['refund_amount']
      || props.formData['transfer_destination_name']
      || props.formData['transfer_destination_contract_number']
    )
      setTransferStatus('В обработке');
    else
      setTransferStatus('Нет');
  }, [props.transferDate, props.formData]);

  useEffect(() => {
    if (props.latinName) {
      props.changeData('transfer_source_name', props.latinName);
    }
    if (props.contractNumber) {
      props.changeData('transfer_source_contract_number', props.contractNumber);
    }
  }, [props.latinName, props.contractNumber]);

  const isTransferReady =
    !!(props.latinName
      && props.contractNumber
      && props.formData.transfer_destination_name
      && props.formData.transfer_destination_contract_number);

  const handleFindUser = useCallback(async (e) => {
    const link = e.target.value;
    if (link.length === 0) {
      setIsLoading(true);
      setTimeout(() => {
        props.changeData('transfer_destination_name', '');
        props.changeData('transfer_destination_contract_number', '');
        setIsLoading(false);
      }, 500);
    } else if (link.length < 25) {
      iziToast.error({
        message: 'Ссылка на слушателя должна быть в формате https://urfucontingent.ru/contract/0000',
        position: "topRight",
        color: "#FFF2ED"
      });
    } else {
      setIsLoading(true);
      const splitLink = e.target.value.split('/');
      const studentId = splitLink[splitLink.length - 1];

      await getStudentsByIdArray([studentId])
        .then((res) => {
          setTimeout(() => {
            props.changeData('transfer_destination_name', res[0].latin_name);
            props.changeData('transfer_destination_contract_number', res[0].contract_number);
            setIsLoading(false);
          }, 500);
        })
        .catch((e) => {
          iziToast.error({
            message: 'Ошибка сервера',
            position: "topRight",
            color: "#FFF2ED"
          });
          setIsLoading(false);
        });
    }
  }, [props.formData]);

  return (
    <>
      <p className="title_contract_doc" style={{ lineHeight: '25px' }}>
        {`Статус переноса: `}
        <br />
        <span
          className="more_info"
          style={{ textDecoration: 'underline' }}
        >
          {transferStatus}
        </span>
      </p>
      {isTransferReady && (
        <div className="info_block">
          <p className="title_contract_doc more_info" style={{ width: '325px' }}>
            Перенос средств с
            <br />
            <b>{props.latinName}</b>
            <br />
            договор <b>{props.contractNumber}</b>
            <br /><br />
            на слушателя
            <br />
            <b>{props.formData.transfer_destination_name}</b>
            <br />
            договор <b>{props.formData.transfer_destination_contract_number}</b>
          </p>
        </div>
      )}
      <p className="subtitle_contract_doc"> Отправитель </p>
      <TextField
        name="transfer_source_name" label="Имя, от кого перенос" variant="outlined"
        value={props.latinName} disabled placeholder={'Автоматическое заполнение'}
        onChange={(e) => props.changeData('transfer_source_name', props.latinName)}
        color="warning" type="text" margin="normal" size="small" sx={{ width: '325px' }}
        inputProps={textFieldStyle} InputLabelProps={dateTextFieldStyle} />
      <TextField
        name="transfer_source_contract_number" label="Номер договора, от кого перенос" variant="outlined"
        value={props.contractNumber} disabled placeholder={'Автоматическое заполнение'}
        onChange={(e) => props.changeData('transfer_source_contract_number', props.contractNumber)}
        color="warning" type="text" margin="normal" size="small" sx={{ width: '325px' }}
        inputProps={textFieldStyle} InputLabelProps={dateTextFieldStyle} />
      <p className="subtitle_contract_doc"> Получатель </p>
      <TextField
        name="transfer_destination_link" label="Ссылка на слушателя, кому перенос" variant="outlined"
        placeholder={'Вставьте ссылку на слушателя'} disabled={props.disabled}
        value={props.formData.transfer_destination_link}
        onChange={(e) => {
          handleFindUser(e);
          props.changeData('transfer_destination_link', e.target.value);
        }}
        color="warning" type="text" margin="normal" size="small" sx={{ width: '325px' }}
        inputProps={textFieldStyle} InputLabelProps={textFieldStyle}
      />
      {isLoading
        ?
        <CircularProgress color="warning" />
        :
        <>
          <TextField
            name="transfer_destination_name" label="Имя слушателя, кому перенос" variant="outlined"
            value={props.formData.transfer_destination_name ? props.formData.transfer_destination_name : ''}
            disabled placeholder={'Автоматическое заполнение'}
            color="warning" type="text" margin="normal" size="small" sx={{ width: '325px' }}
            inputProps={textFieldStyle} InputLabelProps={dateTextFieldStyle} />
          <TextField
            name="transfer_destination_contract_number" label="Номер договора, кому перенос" variant="outlined"
            value={props.formData.transfer_destination_contract_number ? props.formData.transfer_destination_contract_number : ''}
            disabled placeholder={'Автоматическое заполнение'}
            color="warning" type="text" margin="normal" size="small" sx={{ width: '325px' }}
            inputProps={textFieldStyle} InputLabelProps={dateTextFieldStyle} />
        </>
      }
      <TextField
        name="transfer_amount" label="Сумма к переносу" variant="outlined" disabled={props.disabled}
        color="warning" type="text" margin="normal" size="small" sx={{ width: '325px' }}
        defaultValue={props.studentData.transfer_amount}
        onChange={(e) => props.changeData('transfer_amount', e.target.value)}
        inputProps={textFieldStyle} InputLabelProps={textFieldStyle}
      />
      <CustomSingleDatePicker
        name={"transfer_date"}
        label={'Дата переноса средств'}
        required={false}
        size={'default'}
        defaultValue={props.studentData.transfer_date}
        action={props.setTransferDate}
        editMode={props.disabled}
      />
    </>
  );
};

export default FundsTransferStudentCard;