import React from 'react';
import TableCell from '@mui/material/TableCell';
import { Collapse } from '@mui/material';
import Box from '@mui/material/Box';
import TableRow from '@mui/material/TableRow';

import './ExtraStudentInfo.css'

const ExtraStudentInfo = ({row, open}) => {
  return (
    <>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box>
              <div className="info_student_block">
                <div className="student_field">
                  <h5>
                    Первая эл. почта студента:
                  </h5>
                  <p>{row.first_student_email ? row.first_student_email : '—'}</p>
                </div>
                <div className="student_field">
                  <h5>
                    Вторая эл. почта студента:
                  </h5>
                  <p>{row.second_student_email ? row.second_student_email : '—'}</p>
                </div>
                <div className="student_field">
                  <h5>
                    Контактный телефон студента:
                  </h5>
                  <p>{row.contact_phone_number ? row.contact_phone_number : '—'}</p>
                </div>
              </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

export default ExtraStudentInfo;
