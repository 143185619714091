import React, { useState } from 'react';
import '../File/File.css';
import '../../../../../Modals/CreateTaskModal/Modal.css';
import '../../ModalDirectory/dir.css';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import FolderIcon from '@mui/icons-material/Folder';
import DeleteIcon from "@mui/icons-material/Delete";
import Download from "@mui/icons-material/Download";
import { useDispatch } from "react-redux";
import {
  deleteCreateFormFile,
} from "../../../../../../store/slices/ManagerData/manager-data";
import { downloadFile } from "../../../../../../actions/fileManager";
import { fileSizes } from "../../../../../../utils/consts/file";
import { deleteDir } from "../../../../../../store/api-actions";

const CreateFormFile = ({ file }) => {
  const dispatch = useDispatch();

  const [active, setActive] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const calculateFileSize = (fSize) => {
    if (fSize === null || fSize === undefined) {
      return null;
    }
    let tmp = fSize;
    let size = 0;

    while (tmp >= 1024) {
      tmp /= 1024;
      size++;
    }

    return `${tmp.toFixed(1)} ${fileSizes[size]}`;
  };
  const handleMouseOver = () => {
    if (file.type !== 'dir') {
      setActive(true);
    }
  };

  const handleDeleteFile = () => {
    dispatch(deleteCreateFormFile(file));
  };

  return (
    <>
      <div key={file.lastModified} className="file" onMouseOver={handleMouseOver}
           onMouseOut={() => setActive(false)}>
        {
          !active ?
            <>
              <div className="icon_file">
                {file.type === 'dir'
                  ? <FolderIcon/>
                  : <InsertDriveFileOutlinedIcon/>
                }
              </div>
              <div key={file.name} className="file_name">{file.name}</div>
              <div key={file.size} className="file_size">{calculateFileSize(file.size)}</div>
            </>
            :
            <>
              <div className="icon_file">{file.type === 'dir' ? <FolderIcon/> :
                <InsertDriveFileOutlinedIcon/>}</div>
              <div key={file.name}  className="file_name">{file.name}</div>
              <div key={file.size}  className="file_size" onClick={(e) => {
                e.stopPropagation();
                handleDeleteFile(e);
              }}><DeleteIcon/></div>
            </>
        }
      </div>
    </>
  );
};

export default CreateFormFile;
