import DeleteIcon from "@mui/icons-material/Delete";
import MenuItem from "@mui/material/MenuItem";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { FilterEnumAnswers } from "../../../utils/consts/filterEnumAnswers";
import MaskedInput from "react-text-mask";
import { filterOperators } from './filterOperators';
import FilterOperatorsList from './FilterOperatorsList';
import HoursNumberComponent from '../Contract/HoursNumberComponent/HoursNumberComponent';

const GetEqualOperator = () => {
  const equalsFilterOperator = filterOperators[filterOperators.findIndex(operator => operator.value === 'equals')];
  return (
    <option
      value={equalsFilterOperator.value}
      key={equalsFilterOperator.value}
    >
      {equalsFilterOperator.label}
    </option>
  );
};

export function FilterItem({ item, columns, setFilterArr, changeFilterProp }) {
  const [inputType, setInputType] = useState(item.param.type);

  const [dateRange, setDateRange] = useState(
    [
      typeof (item.value) === 'string' ? null : item.value[0],
      typeof (item.value) === 'string' ? null : item.value[1]]
  );
  const [startDate, endDate] = dateRange;
  const [startDateSingleRange, setStartDateSingleRange] = useState(isNaN(new Date(item.value).getDate()) ? null : item.value);

  useEffect(() => {
    if (item.param.type && item.param.type.includes('enum'))
      changeFilterProp(item.id, 'equals', 'operator');
    if (item.param.type && item.param.type === 'text')
      changeFilterProp(item.id, 'coincidence', 'operator')
    if (item.param.type && item.param.type === 'date')
      changeFilterProp(item.id, 'equals', 'operator')
  }, [item.param.type]);

  return (
    <MenuItem>
      <div className="filter_container">
        <select
          name="" id=""
          className="first_parameter search_filter"
          value={item.param.value}
          onChange={(e) => {
            window.scrollTo(0, 0);
            let tmp = columns.filter((item) => e.target.value === item.value);
            setInputType(tmp[0].type);
            changeFilterProp(item.id, tmp[0], 'param');
          }}>
          <option hidden>Выберите поле</option>
          {
            columns
              .filter(item => item.value !== 'id')
              .map((item) => {
                return <option value={item.value} key={item.value}>{item.label}</option>;
              })
          }
        </select>
        <select
          name=""
          id=""
          className="second_parameter search_filter"
          value={item.operator}
          onChange={(e) => {
            changeFilterProp(item.id, e.target.value, 'operator');
          }}
        >
          {
            item.param.type && item.param.type.includes('enum')
              ?
              <GetEqualOperator />
              :
              <FilterOperatorsList item={item} />
          }
        </select>
        <div className="third_parameter">
          {(!FilterEnumAnswers[item.param.value] && item.operator !== 'range' && inputType !== 'date') &&
            <input className="search_filter" type="text"
                   placeholder="Значение для фильтрации"
                   onChange={(e) => {
                     changeFilterProp(item.id, e.target.value, 'value');
                   }}
                   value={item.value} />}
          {(inputType === 'date' && item.operator !== 'range' && item.operator !== 'notNull') &&
            <DatePicker
              /*open={!isDateWithCalendar}*/
              todayButton="Today"
              selected={startDateSingleRange}
              onChange={(date) => {
                if (date !== null) {
                  changeFilterProp(item.id, date, 'value');
                  setStartDateSingleRange(date);
                } else {
                  changeFilterProp(item.id, '', 'value');
                  setStartDateSingleRange(null);
                }
              }}
              showMonthDropdown
              showYearDropdown
              placeholderText="Выберите дату для поиска"
              dateFormat="dd.MM.yyyy"
              className="date_picker_filter"
              isClearable
              customInput={
                <MaskedInput
                  type="text"
                  mask={[/[0-3]/, /\d/, ".", /[0-1]/, /[0-9]/, ".", /[1-2]/, /\d/, /\d/, /\d/]}
                />
              }
            />}
          {(inputType === 'date' && item.operator === 'range') &&
            <DatePicker
              selectsRange={true}
              onChange={(update) => {
                changeFilterProp(item.id, update, 'value');
                setDateRange(update);
              }}
              startDate={startDate}
              endDate={endDate}
              showMonthDropdown
              showYearDropdown
              required
              placeholderText="Выберите диапазон дат"
              dropdownMode="select"
              dateFormat="dd.MM.yyyy"
              className="date_picker_filter"
              isClearable
            />
          }
          {
            item.operator === 'notNull' &&
            <select
              className="search_filter" value={item.value}
              style={{ width: '205px' }} disabled
            >
              <option hidden>Без ввода</option>
            </select>
          }
          {FilterEnumAnswers[item.param.value] && item.param.value === 'hours_number'
            ?
            <HoursNumberComponent
              setHoursNumber={(hoursNumber) => changeFilterProp(item.id, hoursNumber, 'value')}
              initValue={item.value}
              isSearchType={true}
              styles={{head: '30px'}}
            />
            : FilterEnumAnswers[item.param.value] && <select
              className="search_filter" value={item.value}
              style={{ width: '205px' }}
              onChange={(e) => {
                changeFilterProp(item.id, e.target.value, 'value');
              }}
            >
              <option hidden>Выберите значение</option>
              {
                FilterEnumAnswers[item.param.value]['answers']
                  .map(({ id, value }) => <option key={id} value={value}>{value}</option>)
              }
            </select>
          }
        </div>
        <button
          className="delete_filter_button"
          onClick={() => {
            setFilterArr((prevState) => prevState.filter((obj) => {
              return obj.id !== item.id;
            }));
          }}
        >
          <DeleteIcon sx={{ fontSize: 22 }} />
        </button>
      </div>
    </MenuItem>
  )
    ;
}