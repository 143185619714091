import React from 'react';
import '../../Modals/CreateTaskModal/Modal.css';
import StudentDisk from './file/StudentDisk/StudentDisk';
import CreateFormDisk from './file/CreateFormDisk/CreateFormDisk';

const ModalFile = ({ active, setActive, studentId = null }) => {
  return (
    <div className={active ? "modal active" : "modal"} onClick={() => setActive(false)}>
      <div className="modal_content" onClick={e => e.stopPropagation()}>
        {studentId
          ?
          <StudentDisk studentId={studentId}/>
          :
          <CreateFormDisk />
        }
      </div>
    </div>
  );
};
export default ModalFile;