import React, { useState, useRef, lazy, Suspense, useEffect } from 'react';
import { addStudent } from '../../../actions/student';
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  LinearProgress,
  MenuItem,
  ThemeProvider
} from "@mui/material";
import './Contract.css';
import { dateTextFieldStyle, listItemStyle, systemColor, textFieldStyle } from "../../../utils/consts/styles";
import Typography from "@mui/material/Typography";
import { getToken } from "../../../utils/token";
import jwtDecode from "jwt-decode";
import CustomSingleDatePicker from "../../datePicker/CustomSingleDatePicker";
import { pricesAmount } from "../../../utils/consts/hoursNumber";
import { useDispatch, useSelector } from 'react-redux';
import { getFiles } from '../../../store/slices/ManagerData/selectors';
import { clearFiles } from '../../../store/slices/ManagerData/manager-data';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FundsTransfer from './FundsTransfer/FundsTransfer';
import iziToast from 'izitoast';
import HoursNumberComponent from './HoursNumberComponent/HoursNumberComponent';

const ModalFileComponent = lazy(() => import("../filemanager/ModalFile"));

export const REFUND_COMMENT = '\nЕсли слушатель не был зачислен - необходимо оформить доп. соглашение на расторжение договора в 1С';

const getFormObjectFromRef = (ref, objectToSave = {}) => {
  let formDataToSave = new FormData(ref);
  const objectData = {};
  formDataToSave.forEach((value, key) => (objectData[key] = value));

  for (const key in objectToSave) {
    if (!formDataToSave.get(key) && formDataToSave.get(key) !== '') {
      formDataToSave.append(key, objectToSave[key]);
    }
  }

  return [objectData, formDataToSave];
};

export default function Contract() {
  const formRef = useRef(null);

  const [latinName, setLatinName] = useState('');
  const [contractNumber, setContractNumber] = useState('');

  const [active, setActive] = useState(true);
  const [isSkipPassport, setSkipPassport] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClickContract = () => {
    setActive(!active);
  };

  const handleClickSkipPassport = () => {
    setSkipPassport(!isSkipPassport);
  };

  const [transferAndRefundData, setTransferAndRefundData] = useState({});
  const handleChangeTransferData = (key, newValue) => {
    setTransferAndRefundData((prev) => {
      return {
        ...prev,
        [key]: newValue
      };
    });
  };

  const [refundStatus, setRefundStatus] = useState('');
  const [refundDate, setRefundDate] = useState('');
  const [refundAmount, setRefundAmount] = useState('');
  const [isFundsReturned, setIsFundsReturned] = useState(false);
  const handleSetRefundDate = (date) => {
    setRefundDate(date);
    setIsFundsReturned(!!(refundAmount && date));
  };

  const [isTransferComplete, setIsTransferComplete] = useState(false);
  useEffect(() => {
    setIsTransferComplete(transferAndRefundData['transfer_amount'] && transferAndRefundData['transfer_date']);
  }, [transferAndRefundData['transfer_amount'], transferAndRefundData['transfer_date']]);

  useEffect(() => {
    handleChangeTransferData('refund_status', refundStatus);
  }, [refundStatus]);

  const [studentPayer, setStudentPayer] = useState('');
  const [isStudentPayer, setIsStudentPayer] = useState(false);
  const handleStudentPayer = (e) => {
    setIsStudentPayer(e.target.checked);
    if (e.target.checked) {
      if (latinName) {
        handleChangeTransferData('refund_name', latinName);
        setStudentPayer(latinName);
      } else {
        iziToast.error({
          message: 'Ф.И.О. (лат.) слушателя не указано',
          position: "topRight",
          color: "#FFF2ED"
        });
      }
    } else
      setStudentPayer('');
  };

  useEffect(() => {
    handleChangeTransferData('refund_name', studentPayer);
  }, [studentPayer]);

  useEffect(() => {
    handleChangeTransferData('refund_amount', refundAmount);
  }, [refundAmount]);

  const navigate = useNavigate();

  const [contractAmount, setContractAmount] = useState(140000);
  const [firstPayment, setFirstPayment] = useState(0);
  const [secondPayment, setSecondPayment] = useState(0);
  const [thirdPayment, setThirdPayment] = useState(0);
  const [fourthPayment, setFourthPayment] = useState(0);

  const [studentExpelled, setStudentExpelled] = useState('');
  const [RF_location, setRfLocation] = useState('');

  const [isRefundNotEnrollmentStatus, setIsRefundNotEnrollmentStatus] = useState(!studentExpelled || studentExpelled === 'Не зачислен');

  const [refundEnrollmentStatus, setRefundEnrollmentStatus] = useState('');
  useEffect(() => {
    setRefundEnrollmentStatus(studentExpelled === 'Зачислен' || studentExpelled === 'Отчислен' ? 'Да' : 'Нет');
    handleChangeTransferData('refund_enrollment_status', refundEnrollmentStatus);
  }, [studentExpelled, refundEnrollmentStatus]);

  const [refundComment, setRefundComment] = useState('');
  const handleChangeRefundComment = (enrollmentStatus) => {
    if (enrollmentStatus === 'Не зачислен' && !refundComment.includes(REFUND_COMMENT)) {
      setRefundComment(prev => `${prev}${REFUND_COMMENT}`);
    } else if (enrollmentStatus !== 'Не зачислен' && refundComment.includes(REFUND_COMMENT)) {
      setRefundComment(refundComment.replace(REFUND_COMMENT, ''));
    }
  };

  useEffect(() => {
    handleChangeTransferData('refund_comment', refundComment);
  }, [refundComment]);

  const [refundDateReceiptApplication, setRefundDateReceiptApplication] = useState('');
  const [refundDateTransferAccounting, setRefundDateTransferAccounting] = useState('');

  useEffect(() => {
    handleChangeTransferData('refund_date_receipt_application', refundDateReceiptApplication);
  }, [refundDateReceiptApplication]);

  useEffect(() => {
    handleChangeTransferData('refund_date_transfer_accounting', refundDateTransferAccounting);
  }, [refundDateTransferAccounting]);

  useEffect(() => {
    if (refundDate)
      setRefundStatus('Средства возвращены');
    else if (
      refundAmount
      || refundDateReceiptApplication
      || refundDateTransferAccounting
      || studentPayer
      || refundComment
    )
      setRefundStatus('В обработке');
    else
      setRefundStatus('Нет');
  }, [refundDate, refundAmount, studentPayer, refundComment, refundDateReceiptApplication, refundDateTransferAccounting]);

  const [modalFileActive, setModalFileActive] = useState(false);

  const ChangeContractAmountWithHoursNumber = (selectedHoursNumber) => {
    pricesAmount.map(({ hour, cost }) => {
      if (selectedHoursNumber === hour) {
        setContractAmount(cost);
      }
    });
  };

  const [hoursNumber, setHoursNumber] = useState('');
  const handleSetHoursNumber = (value) => setHoursNumber(value);

  const paymentBalance = +contractAmount - +firstPayment - +secondPayment - +thirdPayment - +fourthPayment;
  const percentPayment = ((contractAmount - paymentBalance) * 100 / contractAmount).toFixed(2);

  const GetPaymentStatus = () => {
    if (+paymentBalance === +contractAmount) {
      return 'Не оплачено';
    } else if (paymentBalance === 0) {
      return 'Оплачено';
    } else if (+paymentBalance < +contractAmount) {
      return 'Оплачено частично';
    }
  };

  const handleOpenFileModal = () => {
    setModalFileActive((prev) => !prev);
  };

  const files = useSelector(getFiles);
  const dispatch = useDispatch();

  const [dateStartedLearning, setDateStartedLearning] = useState('');
  const handleSetDateStartedLearning = (date) => {
    setDateStartedLearning(date);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const parsedFormData = getFormObjectFromRef(formRef.current, transferAndRefundData);
    let objectData = parsedFormData[0];
    const dataToSave = parsedFormData[1];

    dataToSave.forEach((value, key) => (objectData[key] = value));

    dataToSave.set('education_type', 'Контракт');
    dataToSave.set('date_creation', new Date().toDateString());
    dataToSave.set('who_created', jwtDecode(getToken()).name);

    dataToSave.set('isSkipPassport', isSkipPassport);

    dataToSave.set('payment_status', GetPaymentStatus());

    dataToSave.set('hours_number', hoursNumber);

    dataToSave.set('birth_date', objectData.birth_date.split('.').reverse().join('-'));
    dataToSave.set('passport_issue_date', objectData.passport_issue_date.split('.').reverse().join('-'));
    dataToSave.set('passport_expiration', objectData.passport_expiration.split('.').reverse().join('-'));
    dataToSave.set('entry_date', objectData.entry_date.split('.').reverse().join('-'));
    dataToSave.set('departure_date', objectData.departure_date.split('.').reverse().join('-'));

    dataToSave.set('enrollment_date', objectData.enrollment_date.split('.').reverse().join('-'));
    dataToSave.set('expulsion_date', objectData.expulsion_date.split('.').reverse().join('-'));

    dataToSave.set('visa_validity', objectData.visa_validity.split('.').reverse().join('-'));
    dataToSave.set('first_payment_contract_date', objectData.first_payment_contract_date.split('.').reverse().join('-'));
    dataToSave.set('second_payment_contract_date', objectData.second_payment_contract_date.split('.').reverse().join('-'));
    dataToSave.set('third_payment_contract_date', objectData.third_payment_contract_date.split('.').reverse().join('-'));
    dataToSave.set('fourth_payment_contract_date', objectData.fourth_payment_contract_date.split('.').reverse().join('-'));
    dataToSave.set('first_payment_actual_date', objectData.first_payment_actual_date.split('.').reverse().join('-'));
    dataToSave.set('second_payment_actual_date', objectData.second_payment_actual_date.split('.').reverse().join('-'));
    dataToSave.set('third_payment_actual_date', objectData.third_payment_actual_date.split('.').reverse().join('-'));
    dataToSave.set('fourth_payment_actual_date', objectData.fourth_payment_actual_date.split('.').reverse().join('-'));
    dataToSave.set('transfer_to_international_service', objectData.transfer_to_international_service.split('.').reverse().join('-'));
    dataToSave.set('transfer_to_MVD', objectData.transfer_to_MVD.split('.').reverse().join('-'));
    dataToSave.set('estimated_receipt_date', objectData.estimated_receipt_date.split('.').reverse().join('-'));
    dataToSave.set('actual_receipt_date_invitation', objectData.actual_receipt_date_invitation.split('.').reverse().join('-'));
    dataToSave.set('started_learning', dateStartedLearning ? 'Да' : 'Нет');
    dataToSave.set('date_started_learning', objectData.date_started_learning.split('.').reverse().join('-'));
    dataToSave.set('registered_in_system', objectData.registered_in_system.split('.').reverse().join('-'));

    dataToSave.set('refund_date_receipt_application', objectData.refund_date_receipt_application.split('.').reverse().join('-'));
    dataToSave.set('refund_date_transfer_accounting', objectData.refund_date_transfer_accounting.split('.').reverse().join('-'));
    dataToSave.set('refund_date', objectData.refund_date.split('.').reverse().join('-'));
    dataToSave.set('transfer_date', objectData.transfer_date.split('.').reverse().join('-'));

    files.map((file) => {
      dataToSave.append('files', file);
    });
    // dataToSave.forEach((value, key) => console.log(key, value));

    addStudent(dataToSave, navigate, setLoading)
      .then(() => {
        dispatch((clearFiles()));
      });
  };

  return (
    <form ref={formRef} onSubmit={handleSubmit} id="studentForm">
      <div className="info_and_education_container">
        <div className="title_contract_section">Основные данные студента</div>
        <div className="columns_position">
          <div className="column_style_contract">
            <p className="title_contract_doc">Личные данные</p>
            <TextField label="Ф.И.О. (лат.)" name="latin_name" variant="outlined" color="warning"
                       type="text" margin="normal"
                       value={latinName} onChange={(e) => setLatinName(e.target.value)}
                       required size="small" sx={{ width: "325px" }}
                       inputProps={textFieldStyle} InputLabelProps={textFieldStyle} />
            <TextField label="Ф.И.О. (кир.)" name="russian_name" variant="outlined" color="warning"
                       type="text" margin="normal" size="small"
                       inputProps={textFieldStyle} InputLabelProps={textFieldStyle} />
            <p className="title_contract_doc"> Контактные данные</p>
            <TextField name="contact_phone_number" label="Контактный телефон студента" variant="outlined"
                       color="warning" type="tel" margin="normal" size="small"
                       inputProps={textFieldStyle} InputLabelProps={textFieldStyle} />
            <TextField name="first_student_email" label="Первая эл. почта студента" variant="outlined"
                       color="warning"
                       type="email" margin="normal" size="small"
                       inputProps={textFieldStyle} InputLabelProps={textFieldStyle} />
            <TextField name="second_student_email" label="Вторая эл. почта студента" variant="outlined"
                       color="warning"
                       type="email" margin="normal" size="small"
                       inputProps={textFieldStyle} InputLabelProps={textFieldStyle} />
            <p className="title_contract_doc"> Контактные данные агента</p>
            <TextField name="agent_name" label="Ф.И.О." variant="outlined" color="warning" type="text"
                       margin="normal" size="small"
                       inputProps={textFieldStyle} InputLabelProps={textFieldStyle} />
            <TextField name="agent_phone_number" label="Телефон" variant="outlined" color="warning"
                       type="tel" margin="normal" size="small"
                       inputProps={textFieldStyle} InputLabelProps={textFieldStyle} />
            <TextField name="first_agent_email" label="Первая эл. почта" variant="outlined" color="warning"
                       type="email"
                       margin="normal" size="small"
                       inputProps={textFieldStyle} InputLabelProps={textFieldStyle} />
            <TextField name="second_agent_email" label="Вторая эл. почта" variant="outlined" color="warning"
                       type="email"
                       margin="normal" size="small"
                       inputProps={textFieldStyle} InputLabelProps={textFieldStyle} />
            <p className="title_contract_doc"> Контактные данные представителя</p>
            <TextField name="representative_name" label="Ф.И.О." type="text" variant="outlined"
                       color="warning" margin="normal" size="small"
                       inputProps={textFieldStyle} InputLabelProps={textFieldStyle} />
            <TextField name="representative_phone_number" label="Телефон" type="tel" variant="outlined"
                       color="warning" margin="normal" size="small"
                       inputProps={textFieldStyle} InputLabelProps={textFieldStyle} />
            <TextField name="first_representative_email" label="Первая эл. почта" type="email"
                       variant="outlined"
                       color="warning" margin="normal" size="small"
                       inputProps={textFieldStyle} InputLabelProps={textFieldStyle} />
            <TextField name="second_representative_email" label="Вторая эл. почта" type="email"
                       variant="outlined"
                       color="warning" margin="normal" size="small"
                       inputProps={textFieldStyle} InputLabelProps={textFieldStyle} />
          </div>
          <div className="column_style_contract">
            <p className="title_contract_doc"> Паспортные данные </p>
            <TextField label="Страна" name="country" type="text" variant="outlined"
                       color="warning" margin="normal" size="small"
                       sx={{ width: "325px" }}
                       inputProps={textFieldStyle} InputLabelProps={textFieldStyle} />
            <TextField label="Пол" name="gender" type="text" variant="outlined" defaultValue=""
                       color="warning" margin="normal" size="small" required select
                       InputLabelProps={textFieldStyle}>
              <MenuItem value="Мужской">
                <span style={listItemStyle}>Мужской</span>
              </MenuItem>
              <MenuItem value="Женский">
                <span style={listItemStyle}>Женский</span>
              </MenuItem>
            </TextField>
            {/*<TextField label="Дата рождения" name='birth_date' type="date" color="warning"
                                   required margin='normal' size="small"
                                   inputProps={textFieldStyle} InputLabelProps={dateTextFieldStyle}/>*/}
            <CustomSingleDatePicker
              name={"birth_date"}
              label={'Дата рождения'}
              required={true}
              size={'default'}
              form="studentForm"
              isOpenCalendar={false}
            />
            <TextField label="Номер паспорта" name="passport_number" type="text" variant="outlined"
                       color="warning" margin="normal" size="small" required
                       inputProps={textFieldStyle} InputLabelProps={textFieldStyle} />

            <label className="checkbox_style_passport_number">
              <input type="checkbox" onClick={handleClickSkipPassport} />
              {`Отключить проверку идентичности паспорта`}
            </label>
            {/*<TextField label="Дата выдачи" name='passport_issue_date' type="date" color="warning"
                                   margin='normal' size="small"
                                   inputProps={textFieldStyle} InputLabelProps={dateTextFieldStyle}/>*/}
            <CustomSingleDatePicker
              name={"passport_issue_date"}
              label={'Дата выдачи'}
              required={false}
              size={'default'}
              isOpenCalendar={false}
            />
            {/*<TextField label="Срок действия паспорта" name='passport_expiration' type="date"
                                   color="warning" margin='normal' size="small"
                                   inputProps={textFieldStyle} InputLabelProps={dateTextFieldStyle}/>*/}
            <CustomSingleDatePicker
              name={"passport_expiration"}
              label={'Срок действия'}
              required={false}
              size={'default'}
              isOpenCalendar={false}
            />
            <TextField label="Кем выдан" name="passport_issued" type="text" variant="outlined"
                       color="warning" margin="normal" size="small"
                       inputProps={textFieldStyle} InputLabelProps={textFieldStyle} />
            <p className="title_contract_doc"> Данные о местоположении </p>
            <TextField label="Место проживания в РФ" name="residence_place" type="text" variant="outlined"
                       color="warning" margin="normal" size="small"
                       inputProps={textFieldStyle} InputLabelProps={textFieldStyle} />
            <TextField label="Нахождение в РФ" type="text"
                       name="RF_location" color="warning" variant="outlined"
                       margin="normal" select size="small" InputLabelProps={textFieldStyle}
                       value={RF_location}
                       onChange={(e) => {
                         setRfLocation(e.target.value);
                       }}>
              <MenuItem value="Да">
                <span style={listItemStyle}>Да</span>
              </MenuItem>
              <MenuItem value="Нет">
                <span style={listItemStyle}>Нет</span>
              </MenuItem>
            </TextField>
            <CustomSingleDatePicker
              name={"entry_date"}
              label={'Дата въезда'}
              required={false}
              size={'default'}
            />
            <CustomSingleDatePicker
              name={"departure_date"}
              label={'Дата отъезда'}
              required={false}
              size={'default'}
            />
            <p className="title_contract_doc"> Дополнительно </p>
            <TextField name="comments" label="Примечания" type="text" variant="outlined"
                       color="warning" margin="normal" size="small" multiline rows={5}
                       inputProps={textFieldStyle} InputLabelProps={textFieldStyle} />
          </div>
        </div>
      </div>
      <div className="info_and_education_container">
        <div className="title_contract_section">Образование студента</div>
        <div className="columns_position">
          <div className="column_style_contract">
            <p className="title_contract_doc"> Полученный уровень образования</p>
            <TextField name="level_education" label="Уровень полученного образования" type="text"
                       variant="outlined" sx={{ width: "325px" }}
                       color="warning" margin="normal" size="small"
                       inputProps={textFieldStyle} InputLabelProps={textFieldStyle} />
            <TextField name="name_educational_institution" label="Наименование учебного заведения"
                       type="text" variant="outlined" color="warning" margin="normal" size="small"
                       inputProps={textFieldStyle} InputLabelProps={textFieldStyle} />
            <p className="title_contract_doc"> Нынешнее образование </p>
            <HoursNumberComponent
              setPaymentAmountAction={ChangeContractAmountWithHoursNumber}
              setHoursNumber={handleSetHoursNumber}
            />
            <TextField name="form_study" label="Форма обучения" type="text" variant="outlined"
                       color="warning" margin="normal" size="small" select defaultValue=""
                       InputLabelProps={textFieldStyle}>
              <MenuItem value="Очная">
                <span style={listItemStyle}>Очная</span>
              </MenuItem>
              <MenuItem value="Гибрид">
                <span style={listItemStyle}>Гибрид</span>
              </MenuItem>
              <MenuItem value="Онлайн">
                <span style={listItemStyle}>Онлайн</span>
              </MenuItem>
            </TextField>
            <p className="title_contract_doc"> Начало обучения </p>
            <CustomSingleDatePicker
              name={"registered_in_system"}
              label={'Зарегистрировался на ПОдИУ'}
              required={false}
              size={'default'}
            />
            <CustomSingleDatePicker
              name={"date_started_learning"}
              label={'Дата приступления к обучению'}
              required={false}
              size={'default'}
              action={handleSetDateStartedLearning}
            />
            <TextField label="Приступил к обучению" name="started_learning" type="text" variant="outlined"
                       defaultValue="" disabled value={dateStartedLearning ? 'Да' : 'Нет'}
                       color="warning" margin="normal" size="small" select
                       InputLabelProps={textFieldStyle}>
              <MenuItem value="Да">
                <span style={listItemStyle}>Да</span>
              </MenuItem>
              <MenuItem value="Нет">
                <span style={listItemStyle}>Нет</span>
              </MenuItem>
            </TextField>
          </div>
          <div className="column_style_contract">
            <p className="title_contract_doc"> Статус </p>
            <TextField
              label="Статус зачисления" name="enrollment" type="text" variant="outlined"
              color="warning" value={studentExpelled}
              onChange={(e) => {
                handleChangeRefundComment(e.target.value);
                setIsRefundNotEnrollmentStatus(e.target.value === 'Не зачислен');
                if (e.target.value === 'Отчислен')
                  setRfLocation('Нет');
                setStudentExpelled(e.target.value);
              }}
              margin="normal" size="small" select sx={{ width: "325px" }}
              InputLabelProps={textFieldStyle}
            >
              <MenuItem value="Зачислен">
                <span style={listItemStyle}>Зачислен</span>
              </MenuItem>
              <MenuItem value="Не зачислен">
                <span style={listItemStyle}>Не зачислен</span>
              </MenuItem>
              <MenuItem value="Отчислен">
                <span style={listItemStyle}>Отчислен</span>
              </MenuItem>
            </TextField>
            <TextField name="enrollment_order" label="Номер приказа о зачислении" type="text"
                       variant="outlined" color="warning" margin="normal" size="small"
                       inputProps={textFieldStyle} InputLabelProps={textFieldStyle} />
            <CustomSingleDatePicker
              name={"enrollment_date"}
              label={'Дата зачисления'}
              required={false}
              size={'default'}
            />
            <TextField name="expulsion_order" label="Номер приказа об отчислении" type="text"
                       variant="outlined" color="warning" margin="normal" size="small"
                       inputProps={textFieldStyle} InputLabelProps={textFieldStyle} />
            <CustomSingleDatePicker
              name={"expulsion_date"}
              label={'Дата отчисления'}
              required={false}
              size={'default'}
            />
            <TextField name="contract_number" label="Номер договора" type="text" variant="outlined"
                       color="warning" margin="normal" size="small"
                       value={contractNumber} onChange={(e) => setContractNumber(e.target.value)}
                       inputProps={textFieldStyle} InputLabelProps={textFieldStyle} />
            <TextField name="status_1c" label="Статус 1C" type="text"
                       variant="outlined" color="warning" defaultValue=""
                       margin="normal" size="small" select
                       InputLabelProps={textFieldStyle}>
              <MenuItem value="Прикреплен">
                <span style={listItemStyle}>Прикреплен</span>
              </MenuItem>
              <MenuItem value="Не прикреплен">
                <span style={listItemStyle}>Не прикреплен</span>
              </MenuItem>
            </TextField>
            <p className="title_contract_doc">Куратор</p>
            <TextField name="tutor_name" label="Куратор (ФИО, номер телефона)" type="text"
                       variant="outlined"
                       color="warning" margin="normal" size="small"
                       inputProps={textFieldStyle} InputLabelProps={textFieldStyle}
            />
          </div>
        </div>
      </div>
      <div className="info_and_education_container">
        <div className="title_contract_section">Приглашение, виза и оплата</div>
        <div className="columns_position">
          <div className="column_style_contract">
            <p className="title_contract_doc"> Виза и приглашение </p>
            <CustomSingleDatePicker
              name={"visa_validity"}
              label={'Срок действия визы'}
              required={false}
              size={'default'}
            />
            <CustomSingleDatePicker
              name={"transfer_to_international_service"}
              label={'Дата передачи в международную службу'}
              required={false}
              size={'default'}
            />
            <CustomSingleDatePicker
              name={"transfer_to_MVD"}
              label={'Дата передачи в МВД'}
              required={false}
              size={'default'}
            />
            <CustomSingleDatePicker
              name={"estimated_receipt_date"}
              label={'Ориентировочная дата получения'}
              required={false}
              size={'default'}
            />
            <CustomSingleDatePicker
              name={"actual_receipt_date_invitation"}
              label={'Фактическая дата получения приглашения'}
              required={false}
              size={'default'}
            />
          </div>
          <div className="column_style_contract" style={{ gap: '10px' }}>
            {isFundsReturned && (
              <div className="info_block">
                <p className="title_contract_doc more_info" style={{ width: '325px' }}>
                  <b>{new Date(refundDate).toLocaleDateString()}</b> средства были
                  <span style={{ color: '#FA7A45' }}> возвращены </span>
                  в размере <b>{refundAmount}</b>&nbsp;руб.
                </p>
              </div>
            )}
            {isTransferComplete && (
              <div className="info_block">
                <p className="title_contract_doc more_info" style={{ width: '325px' }}>
                  <b>{new Date(transferAndRefundData['transfer_date']).toLocaleDateString()}</b> средства были
                  <span style={{ color: '#FA7A45' }}> переведны </span>
                  в размере <b>{transferAndRefundData['transfer_amount']}</b>&nbsp;руб.
                </p>
              </div>
            )}
            <div className="elements_in_row">
              <TextField
                label="Сумма для оплаты" name="contract_amount" type="text"
                sx={{ width: '150px' }}
                onChange={(e) => {
                  setContractAmount(e.target.value);
                }}
                value={contractAmount} variant="outlined"
                color="warning" margin="normal" size="small"
                inputProps={textFieldStyle} InputLabelProps={textFieldStyle} />
            </div>
            <p className="title_contract_doc more_info">
              Оплата / остаток: <b>{paymentBalance}</b> руб.
            </p>
            <p className="title_contract_doc more_info">
              Процент оплаченной суммы: <b>{percentPayment}</b> %
            </p>
            <p className="title_contract_doc more_info">
              Статус оплаты: <b>{GetPaymentStatus()}</b>
            </p>
            <div style={{ width: '325px' }}>
              <Accordion sx={{ borderRadius: '5px', mb: '15px', mt: '10px' }}>
                <AccordionSummary>
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: '15px',
                      color: "#FA7A45",
                      fontWeight: 500,
                      fontFamily: 'Montserrat'
                    }}
                  >
                    Первый платеж
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="elements_in_row">
                    <CustomSingleDatePicker
                      name={"first_payment_contract_date"}
                      label={'Платеж 1 (договор)'}
                      required={false}
                      size={'small'}
                    />
                    <CustomSingleDatePicker
                      name={"first_payment_actual_date"}
                      label={'Платеж 1 (факт)'}
                      required={false}
                      size={'small'}
                    />
                  </div>
                  <TextField
                    label="Сумма платежа" name="amount_first_actual_payment" type="text"
                    color="warning"
                    onChange={(e) => {
                      setFirstPayment(e.target.value);
                    }}
                    sx={{ ml: '50px', mt: '10px', mb: '10px', width: '200px' }}
                    size="small" inputProps={textFieldStyle}
                    InputLabelProps={textFieldStyle} />
                </AccordionDetails>
              </Accordion>
              <Accordion sx={{ borderRadius: '5px', mb: '15px' }}>
                <AccordionSummary
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: '15px',
                      color: "#FA7A45",
                      fontWeight: 500,
                      fontFamily: 'Montserrat'
                    }}
                  >
                    Второй платеж
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="elements_in_row">
                    <CustomSingleDatePicker
                      name={"second_payment_contract_date"}
                      label={'Платеж 2 (договор)'}
                      required={false}
                      size={'small'}
                    />
                    <CustomSingleDatePicker
                      name={"second_payment_actual_date"}
                      label={'Платеж 2 (факт)'}
                      required={false}
                      size={'small'}
                    />
                  </div>
                  <TextField label="Сумма платежа" name="amount_second_actual_payment" type="text"
                             color="warning"
                             onChange={(e) => {
                               setSecondPayment(e.target.value);
                             }}
                             sx={{ ml: '50px', mt: '10px', mb: '10px', width: '200px' }}
                             size="small" inputProps={textFieldStyle}
                             InputLabelProps={textFieldStyle} />
                </AccordionDetails>
              </Accordion>
              <Accordion sx={{ borderRadius: '5px', mb: '15px' }}>
                <AccordionSummary
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: '15px',
                      color: "#FA7A45",
                      fontWeight: 500,
                      fontFamily: 'Montserrat'
                    }}
                  >
                    Третий платеж
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="elements_in_row">
                    <CustomSingleDatePicker
                      name={"third_payment_contract_date"}
                      label={'Платеж 3 (договор)'}
                      required={false}
                      size={'small'}
                    />
                    <CustomSingleDatePicker
                      name={"third_payment_actual_date"}
                      label={'Платеж 3 (факт)'}
                      required={false}
                      size={'small'}
                    />
                  </div>
                  <TextField
                    label="Сумма платежа" name="amount_third_actual_payment" type="text"
                    color="warning"
                    onChange={(e) => setThirdPayment(e.target.value)}
                    sx={{ ml: '50px', mt: '10px', mb: '10px', width: '200px' }}
                    size="small" inputProps={textFieldStyle}
                    InputLabelProps={textFieldStyle} />
                </AccordionDetails>
              </Accordion>
              <Accordion sx={{ borderRadius: '5px', mb: '15px' }}>
                <AccordionSummary
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: '15px',
                      color: "#FA7A45",
                      fontWeight: 500,
                      fontFamily: 'Montserrat'
                    }}
                  >
                    Четвертый платеж
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="elements_in_row">
                    <CustomSingleDatePicker
                      name={"fourth_payment_contract_date"}
                      label={'Платеж 4 (договор)'}
                      required={false}
                      size={'small'}
                    />
                    <CustomSingleDatePicker
                      name={"fourth_payment_actual_date"}
                      label={'Платеж 4 (факт)'}
                      required={false}
                      size={'small'}
                    />
                  </div>
                  <TextField
                    label="Сумма платежа" name="amount_fourth_actual_payment" type="text"
                    color="warning"
                    onChange={(e) => setFourthPayment(e.target.value)}
                    sx={{ ml: '50px', mt: '10px', mb: '10px', width: '200px' }}
                    size="small" inputProps={textFieldStyle}
                    InputLabelProps={textFieldStyle} />
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
      {/*Возврат и перенс средств*/}
      <Accordion sx={{ borderRadius: '5px', mb: '15px', mt: '10px' }}>
        <AccordionSummary>
          <Typography
            variant="body2"
            sx={{
              fontSize: '20px',
              color: '#FF4B00',
              fontWeight: 500,
              fontFamily: 'Montserrat',
              display: 'flex',
              ml: 'auto',
              mr: 'auto'
            }}
          >
            Возврат и перенс средств
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="info_and_education_container">
            {/*Возврат средств*/}
            <div className="center_block">
              <TextField
                label="Был ли зачислен" name="refund_enrollment_status" type="text" variant="outlined"
                defaultValue="" color="warning" margin="normal" size="small" select sx={{ width: '325px' }}
                InputLabelProps={textFieldStyle} value={refundEnrollmentStatus} disabled
              >
                <MenuItem value="Да">
                  <span style={listItemStyle}>Да</span>
                </MenuItem>
                <MenuItem value="Нет">
                  <span style={listItemStyle}>Нет</span>
                </MenuItem>
              </TextField>
              {isRefundNotEnrollmentStatus &&
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={event => {
                        handleChangeTransferData('refund_is_1C_contract_terminated', event.target.checked);
                      }}
                      color="warning"
                    />
                  }
                  label={
                    <p style={textFieldStyle.style}>
                      Доп. соглашение на расторжение договора в 1С
                    </p>
                  }
                />}
              <div className="elements_in_row" style={{ cursor: 'default' }}>
                <TextField
                  name="refund_name" label="Имя фактического плательщика	" variant="outlined"
                  disabled={isStudentPayer}
                  value={studentPayer} onChange={(e) => setStudentPayer(e.target.value)}
                  placeholder="Введите имя фактического плательщика"
                  color="warning" type="text" margin="normal" size="small" sx={{ width: '325px' }}
                  inputProps={textFieldStyle} InputLabelProps={dateTextFieldStyle} />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleStudentPayer}
                      color="warning"
                    />
                  }
                  label={
                    <p style={{ ...textFieldStyle.style, lineHeight: 1 }}>
                      Слушатель является <br /> плательщиком
                    </p>
                  }
                />
              </div>
              <CustomSingleDatePicker
                name={"refund_date_receipt_application"}
                label={'Дата поступления заявки'}
                required={false}
                size={'default'}
                action={setRefundDateTransferAccounting}
              />
              <CustomSingleDatePicker
                name={"refund_date_transfer_accounting"}
                label={'Дата передачи в бухгалтерию'}
                required={false}
                size={'default'}
                action={setRefundDateReceiptApplication}
              />
              <TextField
                name="refund_comment" label="Комментарии возврата и переноса средств" type="text"
                variant="outlined"
                value={refundComment} onChange={(e) => setRefundComment(e.target.value)}
                color="warning" margin="normal" size="small" multiline rows={5} sx={{ width: '400px' }}
                inputProps={textFieldStyle} InputLabelProps={textFieldStyle} />
            </div>
            <div className="columns_position">
              <div className="column_style_contract">
                <p className="title_contract_doc"> Возврат средств </p>
                <TextField
                  label="Статус возврата" name="refund_status" type="text" variant="outlined" defaultValue=""
                  color="warning" margin="normal" size="small" select sx={{ width: '325px' }} disabled
                  value={refundStatus}
                  InputLabelProps={textFieldStyle}
                >
                  <MenuItem value="В обработке">
                    <span style={listItemStyle}>В обработке</span>
                  </MenuItem>
                  <MenuItem value="Средства возвращены">
                    <span style={listItemStyle}>Средства возвращены</span>
                  </MenuItem>
                  <MenuItem value="Нет">
                    <span style={listItemStyle}>Нет</span>
                  </MenuItem>
                </TextField>
                <TextField
                  name="refund_amount" label="Сумма к возврату" variant="outlined"
                  value={refundAmount} onChange={(e) => setRefundAmount(e.target.value)}
                  color="warning" type="text" margin="normal" size="small" sx={{ width: '325px' }}
                  inputProps={textFieldStyle} InputLabelProps={textFieldStyle}
                />
                <CustomSingleDatePicker
                  name={"refund_date"}
                  label={'Дата возврата средств'}
                  required={false}
                  size={'default'}
                  action={handleSetRefundDate}
                />
              </div>
              {/*Перенос средств*/}
              <div className="column_style_contract">
                <p className="title_contract_doc"> Перенос средств </p>
                <FundsTransfer
                  formData={transferAndRefundData} changeData={handleChangeTransferData}
                  latinName={latinName}
                  contractNumber={contractNumber} />
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <label className="checkbox_style_contract">
        <input type="checkbox" onClick={handleClickContract} /> Вы уверены, что хотите добавить студента?
      </label>
      <div className="button_position_contract_doc">
        {!loading
          ?
          <div style={{ display: 'flex', flexDirection: 'row', gap: '20px' }}>
            <button style={{ background: 'none', border: 'none' }} onClick={handleOpenFileModal} type="button">

              <Badge badgeContent={files.length} color="info">
                <InsertDriveFileIcon sx={{ fontSize: 24, color: '#FA7A45', cursor: 'pointer' }} />
              </Badge>
            </button>
            <button type="submit" className="button_style_contract_doc" disabled={active}>
              Добавить
            </button>
          </div>
          :
          <ThemeProvider theme={systemColor}>
            <LinearProgress
              color="primary"
              sx={{
                width: '120px',
                height: '25px',
                mt: "10px",
                mb: "10px",
                borderRadius: '7px'
              }} />
          </ThemeProvider>}
      </div>
      <Suspense fallback={<></>}>
        {modalFileActive && <ModalFileComponent active={modalFileActive} setActive={setModalFileActive} />}
      </Suspense>
    </form>
  );
}