import React, { useEffect, useState } from 'react';
import '../StudentDisk/Disk.css';
import CreateFileList from "../FileList/CreateFileList/CreateFileList";
import { useDispatch } from 'react-redux';
import { addFile } from '../../../../../store/slices/ManagerData/manager-data';

const CreateFormDisk = () => {
  const dispatch = useDispatch();

  const uploadFileHandler = (event) => {
    const files = [...event.target.files];
    files.map((file) => {
      dispatch(addFile(file))
    })
  };

  return (
    <div className="disk">
      <div className="disk_btns">
        <div className="disk_style_btns">
          <label htmlFor="disk_upload_id">Выберите файл</label>
          <input type="file" id="disk_upload_id" style={{ display: "none" }}
                 onChange={uploadFileHandler} multiple={true}/>
        </div>
      </div>
      <CreateFileList />
    </div>
  );
};

export default CreateFormDisk;
