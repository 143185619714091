import TextField from '@mui/material/TextField';
import { listItemStyle, textFieldStyle } from '../../../../utils/consts/styles';
import { MenuItem } from '@mui/material';
import React, { useEffect, useState } from 'react';
import './HoursNumberComponent.css';

const GetYearsEndingString = (program, leftYear, rightYear) => {
  return `${leftYear}-${rightYear}${program && program.includes('1008') ? ' ' : ' уч.'}г.`;
};

const ParseFullHoursNumber = (program) => {
  if (program === '')
    return ['', ''];

  const splitProgram = program && program.split(' ');
  const hoursNumber =
    splitProgram[0] !== '1008'
      ? splitProgram[0]
      : `${splitProgram[0]} ${splitProgram[1]} ${splitProgram[2]})`;
  const yearsRange =
    splitProgram[0] !== '1008'
      ? `${splitProgram[1].replace('(', '')} ${splitProgram[2].replace(')', '')}`
      : `${splitProgram[3]} ${splitProgram[4].replace(')', '')}`;

  return [hoursNumber, yearsRange];
};

const HoursNumberComponent = ({
                                setPaymentAmountAction = (value) => {
                                },
                                setHoursNumber,
                                initValue = '',
                                editMode = false,
                                isSearchType = false,
                              }) => {
  const [parsedProgram, parsedYearsRange] = ParseFullHoursNumber(initValue);

  const [program, setProgram] = useState(parsedProgram ? parsedProgram : '');
  const [yearsVariant, setYearsVariant] = useState(parsedYearsRange ? parsedYearsRange : '');
  const [fullHoursNumber, setFullHoursNumber] = useState(initValue ? initValue : '');

  const handleSelectHoursNumber = (e) => {
    const newProgram = e.target.value;
    if (program && (newProgram.includes('1008') && !program.includes('1008')
      || !newProgram.includes('1008') && program.includes('1008'))
    )
      setYearsVariant('');

    setProgram(newProgram);
  };

  const handleSelectYearsVariant = (e) => setYearsVariant(e.target.value);

  useEffect(() => {
    if (program === '' || yearsVariant === '')
      setFullHoursNumber('');
    if (program && yearsVariant)
      if (program && program.includes('('))
        setFullHoursNumber(`${program.replace(')', '')} ${yearsVariant})`);
      else
        setFullHoursNumber(`${program} (${yearsVariant})`);
  }, [program, yearsVariant]);

  useEffect(() => {
    setHoursNumber(fullHoursNumber);
    setPaymentAmountAction && setPaymentAmountAction(fullHoursNumber);
  }, [fullHoursNumber]);

  return (
    <>
      <div className="elements_in_row">
        <TextField
          label="Программа" type="text" variant="outlined"
          color="warning" margin="normal" size="small" select
          className="hoursInput" disabled={editMode}
          value={program} onChange={handleSelectHoursNumber}
          InputLabelProps={textFieldStyle}
        >
          <MenuItem value="1008 (1.5 года)">
            <span style={listItemStyle}>1008 (1.5 года)</span>
          </MenuItem>
          <MenuItem value="1008 (1 год)">
            <span style={listItemStyle}>1008 (1 год)</span>
          </MenuItem>
          <MenuItem value="868">
            <span style={listItemStyle}>868</span>
          </MenuItem>
          <MenuItem value="728">
            <span style={listItemStyle}>728</span>
          </MenuItem>
          <MenuItem value="588">
            <span style={listItemStyle}>588</span>
          </MenuItem>
          <MenuItem value="504">
            <span style={listItemStyle}>504</span>
          </MenuItem>
          <MenuItem value="352">
            <span style={listItemStyle}>352</span>
          </MenuItem>
          <MenuItem value="288">
            <span style={listItemStyle}>288</span>
          </MenuItem>
          <MenuItem value="144">
            <span style={listItemStyle}>144</span>
          </MenuItem>
          <MenuItem value="108">
            <span style={listItemStyle}>108</span>
          </MenuItem>
          <MenuItem value="72">
            <span style={listItemStyle}>72</span>
          </MenuItem>
        </TextField>
        <TextField
          label="Уч. год" type="text" variant="outlined"
          color="warning" margin="normal" size="small" select
          className="yearRangeInput" disabled={editMode}
          value={yearsVariant} onChange={handleSelectYearsVariant}
          InputLabelProps={textFieldStyle}
        >
          <MenuItem value={GetYearsEndingString(program, 24, 25)}>
            <span style={listItemStyle}>{GetYearsEndingString(program, 24, 25)}</span>
          </MenuItem>
          <MenuItem value={GetYearsEndingString(program, 23, 24)}>
            <span style={listItemStyle}>{GetYearsEndingString(program, 23, 24)}</span>
          </MenuItem>
        </TextField>
      </div>
      {!isSearchType && <p className="resultHoursNumber">
        Количество часов:
        <br />
        <span>
          {fullHoursNumber ? fullHoursNumber : '—'}
        </span>
      </p>}
    </>
  );
};

export default HoursNumberComponent;