import React, { useCallback, useEffect, useState } from 'react';
import '../Contract.css';
import { dateTextFieldStyle, listItemStyle, textFieldStyle } from '../../../../utils/consts/styles';
import { CircularProgress, MenuItem } from '@mui/material';
import TextField from '@mui/material/TextField';
import { getStudentsByIdArray } from '../../../../actions/student';
import MaskedInput from 'react-text-mask/dist/reactTextMask';
import iziToast from 'izitoast';
import { internalServerError } from '../../../../utils/consts/pathRoutes';
import CustomSingleDatePicker from '../../../datePicker/CustomSingleDatePicker';

const FundsTransfer = (props) => {
  const [destinationUser, setDestinationUser] = useState({});
  const [transferDate, setTransferDate] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [transferStatus, setTransferStatus] = useState('');

  useEffect(() => {
    props.changeData('transfer_status', transferStatus);
  }, [transferStatus]);

  useEffect(() => {
    props.changeData('transfer_date', transferDate);
  }, [transferDate]);

  useEffect(() => {
    if (transferDate)
      setTransferStatus('Средства перенесены');
    else if (
      props.formData['transfer_amount']
      || props.formData['refund_date_receipt_application']
      || props.formData['refund_date_transfer_accounting']
      || props.formData['refund_name']
      || props.formData['refund_comment']
      || props.formData['refund_amount']
      || destinationUser['latin_name']
      || destinationUser['contract_number']
    )
      setTransferStatus('В обработке');
    else
      setTransferStatus('Нет');
  }, [transferDate, destinationUser, props.formData]);

  useEffect(() => {
    if (props.latinName) {
      props.changeData('transfer_source_name', props.latinName);
    }
    if (props.contractNumber) {
      props.changeData('transfer_source_contract_number', props.contractNumber);
    }
  }, [props.latinName, props.contractNumber]);

  const isTransferReady = !!(props.latinName && props.contractNumber && destinationUser.latin_name && destinationUser.contract_number);

  const handleFindUser = useCallback(async (e) => {
    const link = e.target.value;

    if (link.length === 0) {
      setIsLoading(true);
      setTimeout(() => {
        setDestinationUser({
          latin_name: '',
          contract_number: ''
        });
        setIsLoading(false);
      }, 500);
    } else if (link.length < 25) {
      iziToast.error({
        message: 'Ссылка на слушателя должна быть в формате https://urfucontingent.ru/contract/0000',
        position: "topRight",
        color: "#FFF2ED"
      });
    } else {
      setIsLoading(true);
      const splitLink = e.target.value.split('/');
      const studentId = splitLink[splitLink.length - 1];

      await getStudentsByIdArray([studentId])
        .then((res) => {
          props.changeData('transfer_destination_name', res[0].latin_name);
          props.changeData('transfer_destination_contract_number', res[0].contract_number);

          setTimeout(() => {
            setDestinationUser(res[0]);
            setIsLoading(false);
          }, 500);
        })
        .catch((e) => {
          console.log(e);
          setIsLoading(false);
        });
    }
  }, [destinationUser, setDestinationUser]);

  return (
    <>
      <TextField
        label="Статус переноса" name="transfer_status" type="text" variant="outlined" defaultValue=""
        color="warning" margin="normal" size="small" select sx={{ width: '325px' }}
        value={transferStatus} disabled
        InputLabelProps={textFieldStyle}>
        <MenuItem value="В обработке">
          <span style={listItemStyle}>В обработке</span>
        </MenuItem>
        <MenuItem value="Средства перенесены">
          <span style={listItemStyle}>Средства перенесены</span>
        </MenuItem>
        <MenuItem value="Нет">
          <span style={listItemStyle}>Нет</span>
        </MenuItem>
      </TextField>
      {isTransferReady && (
        <div className="info_block">
          <p className="title_contract_doc more_info" style={{ width: '325px' }}>
            Перенос средств с
            <br />
            <b>{props.latinName}</b>
            <br />
            договор <b>{props.contractNumber}</b>
            <br /><br />
            на слушателя
            <br />
            <b>{destinationUser.latin_name}</b>
            <br />
            договор <b>{destinationUser.contract_number}</b>
          </p>
        </div>
      )}
      <p className="subtitle_contract_doc"> Отправитель </p>
      <TextField
        name="transfer_source_name" label="Имя, от кого перенос" variant="outlined"
        value={props.latinName} disabled placeholder={'Автоматическое заполнение'}
        color="warning" type="text" margin="normal" size="small" sx={{ width: '325px' }}
        inputProps={textFieldStyle} InputLabelProps={dateTextFieldStyle} />
      <TextField
        name="transfer_source_contract_number" label="Номер договора, от кого перенос" variant="outlined"
        value={props.contractNumber} disabled placeholder={'Автоматическое заполнение'}
        color="warning" type="text" margin="normal" size="small" sx={{ width: '325px' }}
        inputProps={textFieldStyle} InputLabelProps={dateTextFieldStyle} />
      <p className="subtitle_contract_doc"> Получатель </p>
      <TextField
        name="transfer_destination_link" label="Ссылка на слушателя, кому перенос" variant="outlined"
        placeholder={'Вставьте ссылку на слушателя'}
        onChange={(e) => {
          handleFindUser(e);
          props.changeData('transfer_destination_link', e.target.value);
        }}
        color="warning" type="text" margin="normal" size="small" sx={{ width: '325px' }}
        inputProps={textFieldStyle} InputLabelProps={textFieldStyle}
      />
      {isLoading
        ?
        <CircularProgress color="warning" />
        :
        <>
          <TextField
            name="transfer_destination_name" label="Имя слушателя, кому перенос" variant="outlined"
            value={destinationUser.latin_name}
            disabled placeholder={'Автоматическое заполнение'}
            color="warning" type="text" margin="normal" size="small" sx={{ width: '325px' }}
            inputProps={textFieldStyle} InputLabelProps={dateTextFieldStyle} />
          <TextField
            name="transfer_destination_contract_number" label="Номер договора, кому перенос" variant="outlined"
            value={destinationUser.contract_number}
            disabled placeholder={'Автоматическое заполнение'}
            color="warning" type="text" margin="normal" size="small" sx={{ width: '325px' }}
            inputProps={textFieldStyle} InputLabelProps={dateTextFieldStyle} />
        </>
      }
      <TextField
        name="transfer_amount" label="Сумма к переносу" variant="outlined"
        color="warning" type="text" margin="normal" size="small" sx={{ width: '325px' }}
        onChange={(e) => props.changeData('transfer_amount', e.target.value)}
        inputProps={textFieldStyle} InputLabelProps={textFieldStyle}
      />
      <CustomSingleDatePicker
        name={"transfer_date"}
        label={'Дата переноса средств'}
        required={false}
        size={'default'}
        action={setTransferDate}
      />
    </>
  );
};

export default FundsTransfer;