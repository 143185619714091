import React from 'react';
import '../StudentFileList/FileList.css';
import File from '../File/File';
import { useSelector } from "react-redux";
import { getFiles } from "../../../../../../store/slices/ManagerData/selectors";
import CreateFormFile from '../CreateFormFile/CreateFormFile';

const CreateFileList = () => {
  const files = useSelector(getFiles);
  return (
    <div className="filelist">
      <div className="filelist_header">
        <div className="filelist_name">Название</div>
        <div className="filelist_size">Размер</div>
      </div>
      {files.map(file => {
        return <CreateFormFile key={file.name} file={file}/>
      })}
    </div>
  );
};

export default CreateFileList;