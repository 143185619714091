import React from 'react';
import TableCell from '@mui/material/TableCell';
import { lineStyleInTable } from '../../../../utils/consts/styles';
import moment from 'moment';

const CustomTableCell = (props) => {
  return <TableCell align="left">
    <div style={lineStyleInTable}>
      {props.row}
    </div>
  </TableCell>
}

const TeacherHeaderCells = ({row}) => {

  return <>
    <CustomTableCell
      row={row.registered_in_system === null ? 'Не определена' : moment(row.registered_in_system).format("DD.MM.YYYY")}
    />
    <CustomTableCell row={row.russian_name} />
    <CustomTableCell row={row.latin_name} />
    <CustomTableCell row={row.hours_number} />
    <CustomTableCell row={row.gender} />
    <CustomTableCell row={row.education_type} />
    <CustomTableCell row={row.country} />
    <CustomTableCell row={row.form_study} />
  </>
}

export default TeacherHeaderCells;
