import * as React from 'react';
import './mainTable.css';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { useEffect, useState } from "react";
import { getStudents } from '../../../actions/student';
import { Link, NavLink } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import '../Searchbar/Searchbar.css';
import { Button, ButtonGroup, CircularProgress, MenuItem } from "@mui/material";
import { ADD_STUDENT_ROUTE } from "../../../utils/consts/pathRoutes";
import Filter from "../Searchbar/Search/Filter";
import jwt_decode from "jwt-decode";
import TableToolbar from "./TableToolbar";
import TableHeader from "./TableHeader";
import { getToken } from "../../../utils/token";
import './TableHeader/HeaderTable.css';
import { lineStyleInTable, listItemStyle, textFieldStyle } from "../../../utils/consts/styles";
import TextField from "@mui/material/TextField";
import moment from "moment";
import DatePicker from "react-datepicker";
import MaskedInput from "react-text-mask";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import TableRowComponent from './TableRowComponent/TableRowComponent';
import TableSearchComponent from './TableSearchComponent/TableSearchComponent';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function EnhancedTable() {
  const [filteredValues, setFilteredValues] = useState([])

  const linesPerPage = localStorage.getItem('linesPerPage') ? +localStorage.getItem('linesPerPage') : 10;

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories');

  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(linesPerPage);
  const [loading, setLoading] = useState(true);

  const [studentList, setStudentList] = useState([]);

  useEffect(() => {
    getStudents()
      .then(items => {
        setStudentList(items);
        setFilteredValues(items)
      })
      .finally(() => setLoading(false));
  }, []);

  const decodedToken = jwt_decode(getToken());
  const isReaderAccess = decodedToken.role === 'Читатель';
  const isTeacherAccess = decodedToken.role === 'Преподаватель';

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const [selected, setSelected] = useState([]);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      setSelected(filteredValues);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    localStorage.setItem('linesPerPage', event.target.value);
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (studentId) => selected.findIndex(student => student.id === studentId) !== -1;

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - studentList.length) : 0;

  return (
    <div>
      <div className="nav">
        {!isReaderAccess && !isTeacherAccess &&
          <div className="filter_position">
            <NavLink to={ADD_STUDENT_ROUTE} className="add_student_btn">
              Добавить студента <AddIcon />
            </NavLink>
          </div>}
        <TableSearchComponent
          loading={loading}
          studentList={studentList}
          setFilteredValues={setFilteredValues}
        />
        {loading && <CircularProgress color="warning" />}
      </div>
      <Box sx={{ maxWidth: '1500px', marginLeft: 'auto', marginRight: 'auto', paddingTop: '30px' }}>
        <Paper sx={{
          width: '100%',
          mb: 2,
          boxShadow: 'none',
          borderBottom: '1px solid #FA7A45',
          borderRadius: '0px',
          borderTop: '1px solid #FA7A45'
        }}>
          <TableToolbar
            numSelected={selected.length}
            selectedRows={selected.map(student => student.id)}
            selectedStudents={selected}
            selectedEmails={selected.map(student => {
              return {
                id: student.id,
                education_type: student.education_type,
                email: student.first_student_email
              };
            })}
          />
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
            >
              <TableHeader
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={filteredValues.length}
              />
              <TableBody>
                {stableSort(filteredValues, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    return <TableRowComponent
                      key={row.id}
                      row={row}
                      index={index}
                      selected={selected}
                      setSelected={setSelected}
                      isItemSelected={isItemSelected}
                    />;
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            count={filteredValues.length}
            onPageChange={handleChangePage}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25, 50, 100, filteredValues.length]}
            component="div"
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Строк на страницу:"
            labelDisplayedRows={(
              {
                from,
                to,
                count
              }
            ) => `Показано ${from}-${to} (всего ${count})`}
          />
        </Paper>
        <FormControlLabel
          control={<Switch checked={dense} onChange={handleChangeDense} />}
          label="Убрать отступы"
        />
      </Box>
    </div>
  );
}
